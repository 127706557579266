import React, { useState, useContext } from "react";
import { useFormik } from "formik";
import { AxiosResponse } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardHeader, CardBody } from "reactstrap";
import NumberFormat from "react-number-format";
import moment from "moment";
import { toast } from "react-toastify";
import { convertCurrencyToNumber } from "../helpers/helper";
import history from "../helpers/History";
import { userContext } from "../App";
import Loading from "./Loading";
import { IAzureBlobStorageResponse } from "../models/IBlobStorageResponse";
import * as adminApi from "../Apis/admin-api";
import { appInsights } from "../helpers/AppInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import {DatePickerField} from './DatePickerField';
import {inputWidth} from './ElementHelpers';

const CreateAuction = () => {
  const [picture, setPicture] = useState<any>();
  const [fileUpload, setFileUpload] = useState<any>();
  const [fileUploadError, setFileUploadError] = useState<any>();
  const contextConsumer = useContext(userContext);
  const [isLoading, setIsLoading] = useState<any>();

  const postAuction = (values: string) => {
    adminApi
      .postAuction(values)
      .then(res => {
        setIsLoading(false);
        toast.success("Auction created successfully!!");
        history.push("/");
      })
      .catch(error => {
        setIsLoading(false);
        error.response.status === 500
          ? toast.error(`ERROR: ${error.response.data.message}`)
          : toast.error(`ERROR: ${error.response.statusText}`);
        appInsights.trackException({
          error,
          id: "Adding Auction",
          severityLevel: SeverityLevel.Critical,
          properties: {
            values
          }
        });
      });
  };

  const createAuctionPost = async formValuesJson => {
    if (picture !== null && picture !== undefined) {
      var formData = new FormData();
      formData.append("image", picture);
      adminApi
        .postImage(formData)
        .then((response: AxiosResponse<IAzureBlobStorageResponse>) => {
          const blobImageUrl = response.data.imageUrl;
          const imageUrl = blobImageUrl.substring(
            0,
            blobImageUrl.lastIndexOf("?")
          );
          let formValues = JSON.parse(formValuesJson);
          formValues["image"] = imageUrl;
          formValues["blobName"] = response.data.blobName;
          postAuction(JSON.stringify(formValues));
        })
        .catch(err => {
          setIsLoading(false);
          appInsights.trackException({
            error: err,
            id: "Saving Image to Azure Blob",
            severityLevel: SeverityLevel.Critical,
            properties: {
              formData
            }
          });
        });
    } else {
      postAuction(formValuesJson);
    }
  };

  const isFutureAuction = (startDate: string) : boolean => {
    const sDate = new Date(startDate);
    const today = new Date();
    return sDate > today;
  }

  const validate = values => {
    const errors: any = {};

    if (!values.title) {
      errors.title = "Required Field";
    }

    if (!values.description) {
      errors.description = "Required Field";
    }

    if (!values.location) {
      errors.location = "Required Field";
    }

    if (!values.startDate) {
      errors.startDate = "Required Field";
    }

    if (!values.closeDate) {
      errors.closeDate = "Required Field";
     }
    else{
      formik.touched.closeDate = true;
    }

    if (values.startDate !== "" && values.closeDate !== "") {
      const sDate = new Date(values.startDate);
      const cDate = new Date(values.closeDate);
      if (cDate < sDate) {
        errors.closeDate = "Close Date entered occurs before the Start Date";
      }
    }

    if (!values.initialBidAmount) {
      errors.initialBidAmount = "Required Field";
    }

    if (!values.bidIncrementAmount) {
      errors.bidIncrementAmount = "Required Field";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      location: "",
      startDate: "",
      closeDate: "",
      initialBidAmount: "",
      bidIncrementAmount: ""
    },
    validate,
    onSubmit: values => {
      setIsLoading(true);
      const inputValues = JSON.stringify({
        title: values.title,
        description: values.description,
        location: values.location,
        startDate: moment(values.startDate).format('L'),
        closeDate: moment(values.closeDate).format('L'),
        initialBidAmount: convertCurrencyToNumber(values.initialBidAmount),
        bidIncrementAmount: convertCurrencyToNumber(values.bidIncrementAmount),
        firstName: contextConsumer?.givenName,
        lastName: contextConsumer?.surname,
        createdUser: contextConsumer?.userPrincipalName,
        createdDate: new Date(),
        bids: [],
        isOpenAuction: true,
        isFutureAuction: isFutureAuction(values.startDate)
      });
      createAuctionPost(inputValues);
    }
  });

  const fileOnChangeHandler = event => {
    let files = event.target.files;
    if (
      files &&
      files.length === 1 &&
      checkMimeType(event) &&
      checkFileSize(event)
    ) {
      setFileUploadError('');
      setPicture(event.target.files[0]);
      setFileUpload({ image: URL.createObjectURL(files[0]) });
    } else if (files && files.length > 1) {
      setFileUploadError("Please upload only one image");
    } else {
      event.target.value = null; //resetting to null
      setFileUpload({});
      setPicture(null);
    }
  };

  const checkMimeType = event => {
    let files = event.target.files;
    const types = ["png", "jpeg", "jpg", "PNG", "JPEG", "JPG"];
    const fileExtension = getFileExtension(files[0].name);
    if (types.every(type => fileExtension !== type)) {
      setFileUploadError("Acceptable image formats are .jpg, .JPG, .jpeg, .JPEG, .png, .PNG");
      return false;
    }

    return true;
  };

  const checkFileSize = event => {
    const fileSize = event.target.files[0].size;
    const maxFileSize = 5000000; //5MB
    if (Number(fileSize) > Number(maxFileSize)) {
      setFileUploadError("image size cannot be more than 5MB");
      return false;
    }

    return true;
  };

  const getFileExtension = (fileName: string) => {
    return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
  };

  if (isLoading) {
    return <Loading loading={isLoading} />;
  }

  return (
    <>
      <div className="container-fluid">
        <br />
        <Card>
          <CardHeader
            id="createAuctionCardHeader"
            tag="h5"
            title="Create Auction"
          >
            Create Auction
          </CardHeader>
          <CardBody>
            <div className="row">
              <div id="formDiv" className="col-md">
                <form onSubmit={formik.handleSubmit}>
                  <div>
                    <div className="form-group row required">
                      <label
                        htmlFor="title"
                        className="col-sm-5 col-form-label"
                      >
                        Title
                      </label>
                      <div className="col-sm-6">
                        <input
                          id="title"
                          name="title"
                          type="text"
                          className="form-control"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.title}
                          style={inputWidth}
                        />
                        {formik.touched.title && formik.errors.title ? (
                          <div className="text-danger">
                            {formik.errors.title}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group row required">
                      <label
                        htmlFor="description"
                        className="col-sm-5 col-form-label"
                      >
                        Description
                      </label>
                      <div className="col-sm-6">
                        <textarea
                          id="description"
                          name="description"
                          className="form-control"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.description}
                          style={inputWidth}
                        ></textarea>
                        {formik.touched.description &&
                        formik.errors.description ? (
                          <div className="text-danger">
                            {formik.errors.description}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group row required">
                      <label
                        htmlFor="location"
                        className="col-sm-5 col-form-label"
                      >
                        Location
                      </label>
                      <div className="col-sm-6">
                        <input
                          id="location"
                          name="location"
                          type="text"
                          className="form-control"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.location}
                          style={inputWidth}
                        />
                        {formik.touched.location && formik.errors.location ? (
                          <div className="text-danger">
                            {formik.errors.location}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group row required">
                      <label
                        htmlFor="startDate"
                        className="col-sm-5 col-form-label"
                      >
                        Start Date
                      </label>
                      <div className="col-sm-6">
                        <div className='customDatePickerWidth'>
                        <DatePickerField
                          id="startDate"
                          name="startDate"
                          minDate={true}
                          initialValue={formik.values.startDate ? new Date(formik.values.startDate) : null}
                          handleChange={formik.handleChange}
                          handleBlur={formik.handleBlur}
                          >
                        </DatePickerField>
                        {formik.touched.startDate && formik.errors.startDate ? (
                          <div className="text-danger">
                            {formik.errors.startDate}
                          </div>
                        ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="form-group row required">
                      <label
                        htmlFor="closeDate"
                        className="col-sm-5 col-form-label"
                      >
                        Close Date
                      </label>
                      <div className="col-sm-6">
                        <div className='customDatePickerWidth'>
                        <DatePickerField
                          id="closeDate"
                          name="closeDate"
                          minDate={false}
                          initialValue={formik.values.closeDate ? new Date(formik.values.closeDate) : null}
                          handleChange={formik.handleChange}
                          handleBlur={formik.handleBlur}
                          >
                        </DatePickerField>
                          {formik.touched.closeDate && formik.errors.closeDate ? (
                          <div className="text-danger">
                            {formik.errors.closeDate}
                          </div>
                        ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="form-group row required">
                      <label
                        htmlFor="initialBidAmount"
                        className="col-sm-5 col-form-label"
                      >
                        Initial Bid Amount
                      </label>
                      <div className="col-sm-6">
                        <NumberFormat
                          id="initialBidAmount"
                          name="initialBidAmount"
                          thousandSeparator={true}
                          prefix={"$"}
                          allowNegative={false}
                          decimalScale={0}
                          className="form-control"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.initialBidAmount}
                          style={inputWidth}
                        />
                        {formik.touched.initialBidAmount &&
                        formik.errors.initialBidAmount ? (
                          <div className="text-danger">
                            {formik.errors.initialBidAmount}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group row required">
                      <label
                        htmlFor="bidIncrementAmount"
                        className="col-sm-5 col-form-label"
                      >
                        Bid Increment Amount
                      </label>
                      <div className="col-sm-6">
                        <NumberFormat
                          thousandSeparator={true}
                          prefix={"$"}
                          id="bidIncrementAmount"
                          name="bidIncrementAmount"
                          className="form-control"
                          allowNegative={false}
                          decimalScale={0}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.bidIncrementAmount}
                          style={inputWidth}
                        />
                        {formik.touched.bidIncrementAmount &&
                        formik.errors.bidIncrementAmount ? (
                          <div className="text-danger">
                            {formik.errors.bidIncrementAmount}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="itemImage"
                        className="col-sm-5 col-form-label"
                      >
                        Item Image
                      </label>
                      <div className="col-sm-6">
                        <input
                          id="itemImage"
                          name="itemImage"
                          type="file"
                          accept=".png, .PNG, .jpeg, .JPEG, .jpg, .JPG"
                          title="Choose File"
                          onChange={fileOnChangeHandler}
                        />
                        {fileUploadError && (
                          <div className="text-danger">{fileUploadError}</div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 offset-sm-5">
                        <button type="submit" className="btn btn-primary" title="Create Auction"
                          id="createAuction">
                          <FontAwesomeIcon icon="plus-circle" /> Create Auction
                        </button>
                        <button
                          type="button"
                          title="Cancel"
                          className="btn btn-danger"
                          onClick={() => history.push("/")}
                          id="cancel"
                        >
                          <FontAwesomeIcon icon="times-circle" /> Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div id="imageDiv" className="col-md">
                {fileUpload && fileUpload.image && (
                  <img
                    id="target"
                    alt="Preview"
                    height="500px"
                    width="500px"
                    object-fit="contain"
                    src={fileUpload.image}
                  ></img>
                )}
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default CreateAuction;
