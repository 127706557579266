import React, { useEffect, useState } from "react";
import AuctionsList from "./AuctionsList";
import { Tabs, Tab } from "react-bootstrap";
import ClosedAuctionsList from "./ClosedAuctionsList";
import {toast} from 'react-toastify';
import Loading from "./Loading";
import { IAuction } from "../models/IAuction";
import * as userApi from "../Apis/user-api";
import ErrorPage from "../common/Error";
import { appInsights } from "../helpers/AppInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import UpcomingAuctionsList from "./UpcomingAuctionsList";

const Auctions = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [openAuctions, setOpenAuctions] = useState<Array<IAuction>>([]);
  const [closedAuctions, setClosedAuctions] = useState<Array<IAuction>>([]);
  const [upcomingAuctions, setUpcomingAuctions] = useState<Array<IAuction>>([]);
  const [hasErrored, setHasErrored] = useState(false);
  const [key, setKey] = useState('openAuctions');

  useEffect(() => {
    const getOpenAuctions = async () => {
      try {
        const responseData: Array<IAuction> = await userApi.getOpenAuctions();
        setOpenAuctions(responseData);
      } catch (error) {
        setIsLoading(false);
        setHasErrored(true);
        error.response.status === 500 ? toast.error(`ERROR: ${error.response.data.message}`) : toast.error(`ERROR: ${error.response.statusText}`);
        appInsights.trackException({
          error,
          id: 'Fetching open auctions',
          severityLevel: SeverityLevel.Error
        });
      }
    };

    const getClosedAuctions = async () => {
      try {
        const responseData: Array<IAuction> = await userApi.getClosedAuctions();
        setClosedAuctions(responseData);
      } catch (error) {
        setIsLoading(false);
        setHasErrored(true);
        error.response.status === 500 ? toast.error(`ERROR: ${error.response.data.message}`) : toast.error(`ERROR: ${error.response.statusText}`);
        appInsights.trackException({
          error,
          id: 'Fetching closed auctions',
          severityLevel: SeverityLevel.Error
        });
      }
    };

    const getUpcomingAuctions = async () => {
      try {
        const responseData: Array<IAuction> = await userApi.getUpcomingAuctions();
        setUpcomingAuctions(responseData);
      } catch (error) {
        setIsLoading(false);
        setHasErrored(true);
        error.response.status = 500 ?
        toast.error(`ERROR: ${error.response.data.message}`) 
        : toast.error(`ERROR: ${error.response.statusText}`);
        appInsights.trackException({
          error,
          id: 'Fetching upcoming auctions',
          severityLevel: SeverityLevel.Error
        });
      }
    }

    getOpenAuctions();
    getClosedAuctions();
    getUpcomingAuctions();

    const previousTab = sessionStorage.getItem('auctionsTab');
    if(previousTab !== null){
      setKey(previousTab);
    }

    setIsLoading(false);
  }, []);

  const onTabClick = (k: string) => {
    setKey(k);
    sessionStorage.setItem('auctionsTab', k);
  }

  if (isLoading) {
    return <Loading loading={isLoading} /> ;
  }

  if (hasErrored) {
    return <ErrorPage model={"auctions"} />;
  }

  return (
    <>
      <Tabs activeKey={key} id="tabs" onSelect={onTabClick}>
        <Tab eventKey="openAuctions" title="Open Auctions">
          {openAuctions && <AuctionsList items={openAuctions}></AuctionsList>}
          {upcomingAuctions && <UpcomingAuctionsList items={upcomingAuctions}></UpcomingAuctionsList>}
        </Tab>
        <Tab eventKey="closedAuctions" title="Closed Auctions">
          {closedAuctions && (
            <ClosedAuctionsList items={closedAuctions}></ClosedAuctionsList>
          )}
        </Tab>
      </Tabs>
    </>
  );
};

export default Auctions;