import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import * as adminApi from "../Apis/admin-api";
import { appInsights } from "../helpers/AppInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { modalValues } from "./ElementHelpers";

interface IProps {
  modalBody: string;
  modalTitle: string;
  isOpen: boolean;
  isOpenChanged?: (isOpen: boolean) => void;
  id: string;
  isSuccess?: boolean;
  isConfirmSuccess?: (isSuccess: boolean) => void;
}

const CustomModal = (props: IProps) => {
  const {
    modalBody,
    modalTitle,
    isOpen,
    isOpenChanged,
    id,
    isConfirmSuccess,
  } = props;
  let isSuccess = props.isSuccess;

  const [modal, setModal] = useState(false);

  useEffect(() => {
    setModal(isOpen);
  }, [isOpen]);

  const toggle = () => {
    setModal(!modal);
    if (isOpenChanged) {
      isOpenChanged(!modal);
    }
  };

  const onConfirmHandler = () => {
    toggle();
    if (
      modalTitle === modalValues.modalDeleteTitle ||
      modalTitle === modalValues.modalUpcomingDeleteTitle
    ) {
      adminApi
        .deleteAuction(id)
        .then(() => {
          if (isConfirmSuccess) {
            isSuccess = true;
            isConfirmSuccess(isSuccess);
          }
          toast.success("Auction deleted successfully!!");
        })
        .catch((error) => {
          error.response.status === 500
            ? toast.error(error.response.data.message)
            : toast.error(error.response.statusText);
          appInsights.trackException({
            error,
            id: "Delete Auction",
            severityLevel: SeverityLevel.Critical,
            properties: {
              deleteAuctionId: id,
            },
          });
        });
    } else if (
      modalTitle === modalValues.modalDeleteBidTitle
    ) {
      adminApi
        .deleteBid(id)
        .then(() => {
          if (isConfirmSuccess) {
            isSuccess = true;
            isConfirmSuccess(isSuccess);
          }
          toast.success("Bid deleted successfully!!");
        })
        .catch((error) => {
          error.response.status === 500
            ? toast.error(`ERROR: ${error.response.data.message}`)
            : toast.error(`ERROR: ${error.response.statusText}`);
          appInsights.trackException({
            error,
            id: "Delete Bid",
            severityLevel: SeverityLevel.Critical,
            properties: {
              deleteBidId: id,
            },
          });
        });
    } else if (
      modalTitle === modalValues.modalUpcomingCloseTitle
    ) {
      adminApi
        .closeUpcomingAuction(id)
        .then(() => {
          if (isConfirmSuccess) {
            isSuccess = true;
            isConfirmSuccess(isSuccess);
          }
          toast.success("Upcoming auction closed successfully!!");
        })
        .catch((error) => {
          error.response.status === 500
            ? toast.error(`ERROR: ${error.response.data.message}`)
            : toast.error(`ERROR: ${error.response.statusText}`);
          appInsights.trackException({
            error,
            id: "Close upcoming auction",
            severityLevel: SeverityLevel.Critical,
            properties: {
              upcomingAuctionId: id,
            },
          });
        });
    } else {
      //close auction
      adminApi
        .closeAuction(id)
        .then(() => {
          if (isConfirmSuccess) {
            isSuccess = true;
            isConfirmSuccess(isSuccess);
          }
          toast.success("Auction closed successfully!!");
        })
        .catch((error) => {
          error.response.status === 500
            ? toast.error(`ERROR: ${error.response.data.message}`)
            : toast.error(`ERROR: ${error.response.statusText}`);
          appInsights.trackException({
            error,
            id: "Closing Auction",
            severityLevel: SeverityLevel.Critical,
            properties: {
              closingAuctionId: id,
            },
          });
        });
    }
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
        <ModalBody>{modalBody}</ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={onConfirmHandler}
            title={`Confirm ${modalTitle}`}
            id="confirm"
          >
            <FontAwesomeIcon icon="check-circle" /> Confirm
          </Button>
          <Button color="danger" onClick={toggle} title="Cancel" id="cancel">
            <FontAwesomeIcon icon="times-circle" /> Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CustomModal;
