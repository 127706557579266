import { ApplicationInsights } from "@microsoft/applicationinsights-web";

export var appInsights: ApplicationInsights;

export const loadAppInsights = (iKey: string) => { //renamed APP_INSIGHTS_KEY to iKey to avoid ambiguity 
  if (appInsights) return;

  if (
    process.env.NODE_ENV === "production"
  ) {
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: iKey,
        autoTrackPageVisitTime: true,
        enableAutoRouteTracking: true
      }
    });
    appInsights.loadAppInsights();
  } else {
    appInsights = {
      trackEvent: () => {},
      trackException: () => {},
      setAuthenticatedUserContext: () => {}
    } as any as ApplicationInsights;
  }
};
