import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavLink,
  NavItem,
  NavbarToggler,
  Collapse,
  NavbarText
} from "reactstrap";
import { userContext } from "../App";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const contextConsumer = useContext(userContext);
  const loginUrl = `${process.env.REACT_APP_SERVER_BASE_URL}/login`;
  const logoutUrl = `${process.env.REACT_APP_SERVER_BASE_URL}/logout`;
  
  useEffect(()=> {
  }, [contextConsumer]);

  const getLoggedInUserName = (): JSX.Element | null => {
    if (contextConsumer && contextConsumer.isAuthenticated) {
      return (
        <NavItem>
          <NavbarText className="d-none d-md-block text-white" title={contextConsumer.displayName}>
            Welcome: {contextConsumer.displayName}
          </NavbarText>
        </NavItem>
      );
    } else {
      return null;
    }
  };

  const getAdminTabs = (): JSX.Element | null => {
    if (
      contextConsumer &&
      contextConsumer.isAuthenticated &&
      contextConsumer.isAdmin
    ) {
      return (
        <>
        <NavItem>
          <NavLink className="text-white" to="/" tag={Link} title="Auctions">
            Auctions
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="text-white" to="/Admin" tag={Link} title="Admin">
            Admin
          </NavLink>
        </NavItem>
        </>
      );
    } else {
      return null;
    }
  };

  const signOutHandler = (event : React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    window.location.href=`${logoutUrl}`;
  }

  const signInHandler = (event : React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    window.location.href=`${loginUrl}`;
  }

  const getLoginOrSignOut = (): JSX.Element => {

    if (contextConsumer && contextConsumer.isAuthenticated) {
      return (
        <NavItem>
          <NavLink className="text-white" onClick={signOutHandler} to='/' style={{paddingLeft:"10px"}} tag={Link} title="Sign Out">
            Sign Out
          </NavLink>
        </NavItem>
      );
    } else {
      return (
        <NavItem>
          <NavLink className="text-white" onClick={signInHandler} to='/' tag={Link} title="Login">
            Login
          </NavLink>
        </NavItem>
      );
    }
  };

  return (
    <div>
      <Navbar color="primary" light expand="md">
        <NavbarBrand className="text-white" to="/" tag={Link}>
          <h2 title="Home - Employee Benefit Fund Auction">EBF Auction</h2>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            {getAdminTabs()}
          </Nav>
          <Nav className="ml-auto">
            {getLoggedInUserName()}
            {getLoginOrSignOut()}
            </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
