import React from "react";
import PulseLoader from "react-spinners/PulseLoader";

interface IProps {
  loading: boolean;
}

const overrideCSS = `display: block; margin: 5 auto; borderColor: #007bff; position:fixed; top:50%; left:50%; transform:translate(-50%, -50%)`;

const Loading = (props: IProps) => {
  const { loading } = props;
  return (
    <div>
      <PulseLoader
        css={overrideCSS}
        size={40}
        color={"#007bff"}
        loading={loading} />
    </div>
  );
};

export default Loading;