import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { userContext } from "../App";
import history from "../helpers/History";
import BidHistory from "./BidHistory";
import Loading from "./Loading";
import * as userApi from "../Apis/user-api";
import { convertCurrencyToNumber, convertToCurrency } from "../helpers/helper";
import { IAuction } from "../models/IAuction";
import { IBid } from "../models/IBid";
import { appInsights } from "../helpers/AppInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import ErrorPage from "../common/Error";

const ViewBid = () => {
  const contextConsumer = useContext(userContext);
  const [auction, setAuction] = useState<IAuction>();
  const [isBidHistoryOpen, setIsBidHistoryOpen] = useState(true);
  const [isViewBidOpen, setIsViewBidOpen] = useState(true);
  const [isOpenAuction, setIsOpenAuction] = useState(true);
  const [bids, setBids] = useState<Array<IBid>>([]);
  const [modal, setModal] = useState(false);
  const [minBid, setMinBid] = useState(0);
  const [bidSuccess, setBidSuccess] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const { auctionId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [hasErrored, setHasErrored] = useState(false);
  const [validBid, setValidBid] = useState(true);
  const [bidRequired, setBidRequired] = useState(false);
  const [bidAmount, setBidAmount] = useState("");
  const [isModalCollapseOpen, setIsModalCollapseOpen] = useState(false);
  const [currentHighestBid, setCurrentHighestBid] = useState(0);
  const [bidCondition, setBidCondition] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [isFutureAuction, setIsFutureAuction] = useState(false);
  const bidHistoryToggle = () => setIsBidHistoryOpen(!isBidHistoryOpen);
  const viewBidToggle = () => setIsViewBidOpen(!isViewBidOpen);
  const modalCollapseToggle = () => {
    setIsModalCollapseOpen(!isModalCollapseOpen);
    setDisabled(false);
  };

  useEffect(() => {
    setIsUpdated(false);

    userApi
      .getAuctionAndBids(auctionId)
      .then(
        axios.spread((auc, bds) => {
          const auctionData: IAuction = auc.data.auction;
          const bidData: Array<IBid> = bds.data.bids;
          setAuction(auctionData);
          setBids(bidData);
          if (auctionData) {
            setIsOpenAuction(auctionData.isOpenAuction);
            setIsFutureAuction(auctionData.isFutureAuction);
            setCurrentHighestBid(
              auctionData.bids.length > 0
                ? Number(auctionData.bids[0].amount)
                : Number(auctionData.initialBidAmount)
            );
            const bidIncrement = auctionData.bidIncrementAmount;
            setMinBid(
              auctionData.bids.length > 0
                ? Number(auctionData.bids[0].amount) + Number(bidIncrement)
                : Number(auctionData.initialBidAmount)
            );
          }
          setIsLoading(false);
        })
      )
      .catch((error) => {
        setIsLoading(false);
        setHasErrored(true);
        error.response.status === 500
          ? toast.error(`ERROR: ${error.response.data.message}`)
          : toast.error(`ERROR: ${error.response.statusText}`);
        appInsights.trackException({
          error,
          id: "Fetching auction and bid history in View bids",
          severityLevel: SeverityLevel.Critical,
          properties: {
            auctionId,
          },
        });
      });
  }, [bidSuccess, isUpdated, isOpenAuction, auctionId]);

  const toggle = () => {
    setModal(!modal);
    if (modal) {
      setValidBid(true);
    }

    if (isModalCollapseOpen) {
      setIsModalCollapseOpen(false);
    }

    setDisabled(false);
  };

  const bidPost = async (bidJson: string) => {
    try {
      await userApi.postBid(bidJson);
      setModal(!modal);
      setBidSuccess(true);
      toast.success("Bid Posted Successfully!!");
    } catch (error) {
      setModal(!modal);
      error.response.status === 500
        ? toast.error(`ERROR: ${error.response.data.message}`)
        : toast.error(`ERROR: ${error.response.statusText}`);
    }
  };

  const handleOnChange = (value) => {
    value === "" ? setBidRequired(true) : setBidAmount(value);
  };

  const handleOnBlur = (value) => {
    value === "" ? setBidRequired(true) : setBidAmount(value);
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    setBidSuccess(false);
    if (event.target.bidAmount.value === "") {
      setBidRequired(true);
      return;
    } else {
      setBidRequired(false);

      if (checkIfBidValid(bidAmount)) {
        setValidBid(true);
        const bidJson = JSON.stringify({
          auctionKey: auctionId,
          amount: convertCurrencyToNumber(bidAmount),
          email: contextConsumer?.userPrincipalName,
          firstName: contextConsumer?.givenName,
          lastName: contextConsumer?.surname,
          submissionDate: new Date(),
          highestBidIndicator: false,
          winningBidIndicator: false,
          createdUserName: contextConsumer?.userPrincipalName,
          createTimeStamp: new Date(),
        });
        bidPost(bidJson);
      }
    }
  };

  const validationText = () => {
    return <div className="text-danger">Required Field</div>;
  };

  const minBidText = (): JSX.Element => {
    if (validBid) {
      return (
        <small>
          The lowest you may bid is now:{" "}
          <strong>{convertToCurrency.format(Number(minBid))}</strong>
        </small>
      );
    } else {
      return (
        <div className="text-danger">
          The lowest you may bid is{" "}
          <strong>{convertToCurrency.format(Number(minBid))}</strong>
        </div>
      );
    }
  };

  const checkIfBidValid = (value: string): boolean => {
    const userBid = convertCurrencyToNumber(value);
    const moreThan50Percent = 1.5 * Number(currentHighestBid);
    if (Number(userBid) < Number(minBid)) {
      setValidBid(false);
      return false;
    } else {
      let conditions = "";
      if (Number(userBid) >= moreThan50Percent) {
        conditions = "moreThan50Percent";
      }
      if (Number(userBid) >= 100) {
        conditions = "moreThan100";
      }
      if (Number(userBid) >= 100 && Number(userBid) >= moreThan50Percent) {
        conditions = "both";
      }
      setBidCondition(conditions);
      if (conditions !== "") {
        setDisabled(true);
        setIsModalCollapseOpen(!isModalCollapseOpen);
        return false;
      } else {
        return true;
      }
    }
  };

  const modalBidText = (condition: string) => {
    let modalText = "";
    if (condition === "moreThan50Percent") {
      modalText = `Your bid is more than 50% higher than the current highest bid.`;
    }

    if (condition === "moreThan100") {
      modalText = "Your bid is more than $100.";
    }

    if (condition === "both") {
      modalText = `Your bid is more than $100 and is 50% higher than the current highest bid.`;
    }

    return modalText;
  };

  const onModalConfirmationHandler = () => {
    const bidJson = JSON.stringify({
      auctionKey: auctionId,
      amount: convertCurrencyToNumber(bidAmount),
      email: contextConsumer?.userPrincipalName,
      firstName: contextConsumer?.givenName,
      lastName: contextConsumer?.surname,
      submissionDate: new Date(),
      highestBidIndicator: false,
      winningBidIndicator: false,
      createdUserName: contextConsumer?.userPrincipalName,
      createTimeStamp: new Date(),
    });

    bidPost(bidJson);
  };

  if (isLoading) {
    return <Loading loading={isLoading} />;
  }

  if (hasErrored) {
    return <ErrorPage model="auction and bids" />;
  }

  return (
    <>
      <div>
        <Modal className="modal-dialog-centered" isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Submit Bid</ModalHeader>
          <ModalBody>
            <div>
              <form onSubmit={onSubmitHandler}>
                <div className="form-group row">
                  <label htmlFor="bid" className="col-form-label col-sm-4">
                    <strong>Bid Amount: </strong> <br />
                  </label>
                  <div className="col-sm-8">
                    <NumberFormat
                      className="form-control"
                      id="bidAmount"
                      name="bidAmount"
                      onChange={(e) => handleOnChange(e.target.value)}
                      onBlur={(e) => handleOnBlur(e.target.value)}
                      allowNegative={false}
                      decimalScale={0}
                      thousandSeparator={true}
                      prefix={"$"}
                      disabled={disabled}
                    />
                    {bidRequired && validationText()}
                    {minBidText()}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 offset-sm-6">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={disabled}
                      title="Submit Bid"
                      id="submitBid"
                    >
                      <FontAwesomeIcon icon="check-circle" /> Submit
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      title="Cancel"
                      id="cancel"
                      onClick={toggle}
                      disabled={disabled}
                    >
                      <FontAwesomeIcon icon="times-circle" /> Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div>
              <br />
              <Collapse isOpen={isModalCollapseOpen}>
                <div
                  className="row"
                  style={{ borderTop: "1px solid #dee2e6", padding: "16px" }}
                >
                  <div className="col-sm-12">
                    <label htmlFor="bid">
                      {bidCondition !== "" && modalBidText(bidCondition)}
                    </label>
                    <label>Do you want to proceed with your bid?</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 offset-sm-6">
                    <button
                      type="submit"
                      title="Confirm Bid"
                      id="confirmBid"
                      className="btn btn-primary"
                      onClick={onModalConfirmationHandler}
                    >
                      <FontAwesomeIcon icon="check-circle" /> Confirm
                    </button>
                    <button
                      type="button"
                      title="Close"
                      id="close"
                      className="btn btn-danger"
                      onClick={modalCollapseToggle}
                    >
                      <FontAwesomeIcon icon="times-circle" /> Close
                    </button>
                  </div>
                </div>
              </Collapse>
            </div>
          </ModalBody>
        </Modal>
      </div>
      <br />
      <div className="container">
        <div>
          <Card>
            <CardHeader
              tag="a"
              onClick={viewBidToggle}
              title="View Bid"
              id="viewBidCardHeader"
            >
              {isViewBidOpen ? (
                <FontAwesomeIcon icon="minus-circle" />
              ) : (
                <FontAwesomeIcon icon="plus-circle" />
              )}{" "}
              View Bid
            </CardHeader>
            <Collapse isOpen={isViewBidOpen}>
              <CardBody>
                {auction ? (
                  <div className="row">
                    <div id="auctionDetails" className="col-md">
                      {auction && (
                        <div>
                          <div className="form-group row">
                            <label
                              htmlFor="title"
                              className="col-sm-5 col-form-label"
                            >
                              Title
                            </label>
                            <div className="col-sm-6">
                              {/* <input
                                type="text"
                                value={auction.title}
                                className="form-control"
                                readOnly
                                id='title'
                              /> */}
                              <textarea
                                className="form-control"
                                readOnly
                                id="title"
                              >
                                {auction.title}
                              </textarea>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="location"
                              className="col-sm-5 col-form-label"
                            >
                              Location
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                value={auction.location}
                                className="form-control"
                                readOnly
                                id="location"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="description"
                              className="col-sm-5 col-form-label"
                            >
                              Description
                            </label>
                            <div className="col-sm-6">
                              {/* <input
                                type="text"
                                value={auction.description}
                                className="form-control"
                                readOnly
                                id="description"
                              /> */}
                              <textarea
                                className="form-control"
                                readOnly
                                id="description"
                                rows={7}
                              >
                                {auction.description}
                              </textarea>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="startDate"
                              className="col-sm-5 col-form-label"
                            >
                              Start Date
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                value={`${auction.startDate} 07:00 AM`}
                                className="form-control"
                                readOnly
                                id="startDate"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="closeDate"
                              className="col-sm-5 col-form-label"
                            >
                              Close Date
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                value={`${auction.closeDate} 05:00 PM`}
                                className="form-control"
                                readOnly
                                id="closeDate"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="currentHighestBid"
                              className="col-sm-5 col-form-label"
                            >
                              Current Highest Bid
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control"
                                readOnly
                                id="currentHighestBid"
                                value={
                                  auction.bids.length > 0
                                    ? convertToCurrency.format(
                                        Number(auction.bids[0].amount)
                                      )
                                    : convertToCurrency.format(
                                        Number(auction.initialBidAmount)
                                      )
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="bidIncrementAmount"
                              className="col-sm-5 col-form-label"
                            >
                              Bid Increment Amount
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control"
                                readOnly
                                id="bidIncrementAmount"
                                value={convertToCurrency.format(
                                  Number(auction.bidIncrementAmount)
                                )}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-sm-5"></div>
                            <div className="col-sm-offset-2 col-sm-6">
                              {isOpenAuction && !isFutureAuction && (
                                <button
                                  type="submit"
                                  id="bid"
                                  className="btn btn-primary"
                                  onClick={toggle}
                                  title="Bid"
                                >
                                  <FontAwesomeIcon icon="check-circle" /> Bid
                                </button>
                              )}
                              <button
                                type="button"
                                className="btn btn-danger"
                                id={isOpenAuction ? "Cancel" : "Back"}
                                title={isOpenAuction ? "Cancel" : "Back"}
                                onClick={() => {
                                  history.goBack();
                                }}
                              >
                                {isOpenAuction ? (
                                  <>
                                    <FontAwesomeIcon icon="times-circle" />{" "}
                                    Cancel
                                  </>
                                ) : (
                                  <>
                                    <FontAwesomeIcon icon="arrow-circle-left" />{" "}
                                    Back
                                  </>
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div id="image" className="col-md">
                      {auction && auction.image && (
                        <img
                          id="target"
                          alt="Preview"
                          height="400px"
                          width="400px"
                          object-fit="contain"
                          src={auction.image}
                        ></img>
                      )}
                    </div>
                  </div>
                ) : (
                  <h5 className="text-center">Could not find an auction</h5>
                )}
              </CardBody>
            </Collapse>
          </Card>
        </div>
        <br />
        <div>
          <Card>
            <CardHeader
              tag="a"
              onClick={bidHistoryToggle}
              title="Bid History"
              id="bidHistoryCardHeader"
            >
              {isBidHistoryOpen ? (
                <FontAwesomeIcon icon="minus-circle" />
              ) : (
                <FontAwesomeIcon icon="plus-circle" />
              )}{" "}
              Bid History
            </CardHeader>
            <Collapse isOpen={isBidHistoryOpen}>
              <CardBody>
                <BidHistory
                  isOpenAuction={isOpenAuction}
                  items={bids}
                  isUpdated={isUpdated}
                  isUpdateChanged={(isUpdated: boolean) =>
                    setIsUpdated(isUpdated)
                  }
                ></BidHistory>
              </CardBody>
            </Collapse>
          </Card>
        </div>
      </div>
    </>
  );
};

export default ViewBid;
