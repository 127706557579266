import React, { useState, createContext, useEffect } from "react";
import { Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "react-toastify/dist/ReactToastify.min.css";
import history from "./helpers/History";
import { loadIcons } from "./helpers/FontAwesomeIconsLibrary";
import { initializeApi } from "./Apis/index";
import { IUser } from "./models/IUser";
import * as userApi from "./Apis/user-api";
import { IUserObject } from "./models/IUserObject";
import { appInsights, loadAppInsights } from "./helpers/AppInsights";
import Routes from "./components/Routes";
import { ToastContainer, toast } from "react-toastify";
import Loading from './components/Loading';

export const userContext = createContext<IUserObject | undefined>(undefined);

function App() {
  initializeApi();
  loadIcons();

  history.listen((location, action) => {
    appInsights.trackEvent({
      name: "Route Change",
      properties: {
        route: location.pathname
      }
    });
  });

  const [isLoading, setIsLoading] = useState(true);
  const [configContext, setConfigContext] = useState<IUserObject | undefined>(
    undefined
  );

  useEffect(() => {
    userApi
      .getUser()
      .then((user: IUser) => {
        if (Object.entries(user).length !== 0) {
          const userInfo: IUserObject = {
            isAuthenticated: true,
            ...user
          }
          setConfigContext(userInfo);
          loadAppInsights(process.env.REACT_APP_APP_INSIGHTS_KEY!);
          appInsights.setAuthenticatedUserContext(userInfo.displayName);
          setIsLoading(false);
        }else{
          setIsLoading(false);
        }
      })
      .catch(error => {
        setIsLoading(false);
        error.response.status === 500 ? toast.error(`ERROR: ${error.response.data.message}`) : toast.error(`ERROR: ${error.response.statusText}`);
      });
  }, []);

  if (isLoading) {
    return <Loading loading={isLoading} /> ;
  }

  return (
    <>
      <ToastContainer autoClose={5000} />
      <Router history={history}>
        <userContext.Provider value={configContext}>
          <Routes></Routes>
        </userContext.Provider>
      </Router>
    </>
  );
}

export default App;
