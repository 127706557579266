import axios from "axios";
import history from "../helpers/History";

export const initializeApi = (): void => {
  axios.defaults.withCredentials = true;
  axios.defaults.headers.get['Pragma'] = 'no-cache';
  axios.defaults.headers.get['Cache-Control']= 'no-cache, no-store';

  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        console.log(error.response);
        history.push("/NotAuthorized");
      }

      return Promise.reject(error);
    }
  );
};