import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker"; 

interface IProps {
    id: string;
    name: string;
    initialValue: Date | null;
    handleChange: (event: React.ChangeEvent) => void;
    handleBlur: (event: React.ChangeEvent) => void;
    minDate: boolean;
}

 

export const DatePickerField: React.FC<IProps> = (props: IProps) => {
    const [date, setDate] = useState<Date | null>(null);

    useEffect(() => {
        setDate(props.initialValue)
    }, [props.initialValue])

    return (
        <DatePicker
            id={props.id}
            name={props.name}
            className="form-control"
            selected={date}
            minDate={props.minDate ? new Date() : null}
            onChange={(newDate) => {
                setDate(newDate);
                const fakeEvent: any = { target: { name: props.name, value: newDate } };
                props.handleChange(fakeEvent);
            }}
            onBlur={(event) => {
                // Hack because react component does not apply the id or name 
                // attribute to the element that generates the blur event
                if (event.target) {
                    event.target.name = props.id;
                }
                const anotherFake: any = {target:{name: props.name, value: event}}
                props.handleBlur(anotherFake);
            }}
        />
    );
};