import React, { useContext, useState } from "react";
import CustomModal from "./CustomModal";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Sort,
  Search,
  Toolbar
} from "@syncfusion/ej2-react-grids";
import { userContext } from "../App";
import { IBid, IBidGridComponent } from "../models/IBid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {bidsDataHelper} from '../helpers/GridComponentDataHelper';
import { modalValues } from "./ElementHelpers";

interface IProps {
  items: Array<IBid>;
  isUpdated?: boolean;
  isUpdateChanged?: (isUpdated: boolean) => void;
  isOpenAuction: boolean;
}

const BidHistory = (props: IProps) => {
  const { isUpdated, isUpdateChanged, items} = props;
  const contextConsumer = useContext(userContext);
  const [bidId, setBidId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  let mappedBids: Array<IBidGridComponent> = [];
  if(items.length> 0){
    mappedBids = bidsDataHelper(items);
  }

  //below is temp solution for page not scrolling issue when bids count is 0.
  if(items.length===0){
    var body: HTMLCollection = document.getElementsByTagName('body');
    body[0].classList.remove('modal-open');
  }

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const actionsTemplate = (item: IBid) => {
    return (
      <button
        type="button" title='Delete Bid'
        className="btn btn-danger"
        value={item._id}
        id={`bidId_${item._id}`}
        onClick={() => {
          setBidId(item._id);
          toggle();
        }}
      >
        <FontAwesomeIcon icon="trash-alt" /> Delete
      </button>
    );
  };

  const bidEmailTemplate = (item: IBid) => {
    return <a href={`mailto:${item.email}`} title={`mailto:${item.email}`}>{item.email}</a>;
  };

  if (props.items.length === 0) {
    return (
      <>
        <h5 className="text-center">No bids found!</h5>
      </>
    );
  }

  return (
    <>
      <CustomModal
        modalTitle= {modalValues.modalDeleteBidTitle}
        modalBody={modalValues.modalDeleteBidBody}
        id={bidId}
        isOpen={isModalOpen}
        isOpenChanged={(isOpen: boolean) => setIsModalOpen(isOpen)}
        isSuccess={isUpdated}
        isConfirmSuccess={(isSuccess: boolean) => {
          if (isUpdateChanged) {
            isUpdateChanged(isSuccess);
          }
        }}
      ></CustomModal>

      <GridComponent
        dataSource={mappedBids}
        toolbar={["Search"]}
        allowPaging={true}
        pageSettings={{ pageSize: 10, pageSizes: ["10", "25", "50", "100"] }}
        allowSorting={true}
        searchSettings={{ ignoreCase: true, operator: "contains" }}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="fullName"
            headerText="Name"
            width="100"
          ></ColumnDirective>
          <ColumnDirective
            field="email"
            headerText="Email"
            template={bidEmailTemplate}
            visible={
              contextConsumer &&
              contextConsumer.isAuthenticated &&
              contextConsumer.isAdmin
            }
            width="100"
          ></ColumnDirective>
          <ColumnDirective
            field="date"
            headerText="Bid Date"
            width="100"
          ></ColumnDirective>
          <ColumnDirective
            field="bidAmount"
            headerText="Bid Amount"
            width="75"
          ></ColumnDirective>
          <ColumnDirective
            headerText="Actions"
            visible={
              contextConsumer &&
              contextConsumer.isAuthenticated &&
              contextConsumer.isAdmin 
            }
            template={actionsTemplate}
            allowSearching={false}
            allowFiltering={false}
            allowSorting={false}
            width="75"
          ></ColumnDirective>
        </ColumnsDirective>
        <Inject services={[Search, Toolbar, Page, Sort]} />
      </GridComponent>
    </>
  );
};

export default BidHistory;
