import React from "react";

export const Home = () => {
  return (
    <div>
      <div className="container">
        <br />
        <br />
        <br />
        <h1 className="text-center" title="Employee Benefit Fund Auction">
          Welcome to EBF Auction
        </h1>

        <p className="text-center">
          Click the following button to login and place your bid for front row
          parking and to support the CO Employee Benefit Fund (EBF). <br/>
          <a id='ebfInfoLink' target='_blank' rel='noopener noreferrer' href='http://infonet.dot.state.fl.us/agencyresources/ebf.shtml'>Click here</a> to find more information about EBF.
        </p>

        <div className="text-center">
          <button
            className="btn btn-primary"
            type="button"
            onClick={() =>
              (window.location.href = `${process.env.REACT_APP_SERVER_BASE_URL}/login`)
            }
          >
            LOGIN
          </button>
        </div>
        <br />
        <p className="text-center">
          Please note: Access to this application is limited to FDOT personnel.
        </p>
      </div>
    </div>
  );
};
