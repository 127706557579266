import React, { useState, useContext } from "react";
import { IAuction, IAuctionGridComponent } from "../models/IAuction";
import { Card, CardHeader, CardBody, Collapse } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Sort,
  Resize,
  Page,
} from "@syncfusion/ej2-react-grids";
import { auctionsDataHelper } from "../helpers/GridComponentDataHelper";
import history from "../helpers/History";
import { userContext } from "../App";
import CustomModal from "./CustomModal";
import { titleTemplate, modalValues } from "./ElementHelpers";
import { gridPagerSettings } from "../helpers/helper";

interface IProps {
  items: Array<IAuction>;
  indicator?: string;
  isUpdated?: boolean;
  isUpdateChanged?: (isUpdated: boolean) => void;
}

const UpcomingAuctionsList = (props: IProps) => {
  const { indicator, items, isUpdated, isUpdateChanged } = props;
  const [isUpcomingAuctionOpen, setIsUpcomingAuctionOpen] = useState(true);
  let mappedAuctions: Array<IAuctionGridComponent> = [];
  const contextConsumer = useContext(userContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [auctionId, setAuctionId] = useState("");

  // if(indicator === 'admin'){
  //     setIsUpcomingAuctionOpen(false);
  // }

  const upcomingAuctionToggle = () => {
    setIsUpcomingAuctionOpen(!isUpcomingAuctionOpen);
  };

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  if (items.length > 0) {
    mappedAuctions = auctionsDataHelper(items);
  }

  const getActionItemsTemplate = (item: IAuction) => {
    const viewBidUrl = `/Auction/View/${item._id}`;
    if (
      contextConsumer &&
      contextConsumer.isAuthenticated &&
      contextConsumer.isAdmin &&
      indicator === "admin"
    ) {
      return (
        <>
          <span>
            <button
              className="btn btn-primary"
              onClick={() => history.push(viewBidUrl)}
            >
              <FontAwesomeIcon icon="eye" /> View Bids
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => {
                setModalBody(modalValues.modalUpcomingCloseBody);
                setModalTitle(modalValues.modalUpcomingCloseTitle);
                setAuctionId(item._id);
                toggle();
              }}
            >
              <FontAwesomeIcon icon="times-circle" /> Close Auction
            </button>
            <button
              className="btn btn-danger"
              onClick={() => {
                setModalBody(modalValues.modalUpcomingDeleteBody);
                setModalTitle(modalValues.modalUpcomingDeleteTitle);
                setAuctionId(item._id);
                toggle();
              }}
            >
              <FontAwesomeIcon icon="trash-alt" /> Delete Auction
            </button>
          </span>
        </>
      );
    } else {
      return (
        <>
          <button
            className="btn btn-primary"
            onClick={() => history.push(viewBidUrl)}
          >
            <FontAwesomeIcon icon="eye" /> View
          </button>
        </>
      );
    }
  };

  const getSmallGrid = () => {
    return (
      <>
        <GridComponent
          dataSource={mappedAuctions}
          allowSorting={true}
          allowResizing={true}
          allowPaging={true}
          pageSettings={{ ...gridPagerSettings() }}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="title"
              headerText="Title"
              width="175"
              template={titleTemplate}
            />
            <ColumnDirective
              field="startDate"
              headerText="Start Date"
              width="175"
            />
            {contextConsumer &&
              contextConsumer.isAuthenticated &&
              contextConsumer.isAdmin &&
              indicator === "admin" && (
                <ColumnDirective
                  headerText="Actions"
                  template={getActionItemsTemplate}
                  allowSearching={false}
                  allowFiltering={false}
                  allowSorting={false}
                  width="450"
                />
              )}
          </ColumnsDirective>
          <Inject services={[Resize, Sort, Page]} />
        </GridComponent>
      </>
    );
  };

  const getMidSizeGrid = () => {
    return (
      <>
        <GridComponent
          dataSource={mappedAuctions}
          allowSorting={true}
          allowResizing={true}
          allowPaging={true}
          pageSettings={{ ...gridPagerSettings() }}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="location"
              headerText="Location"
              width={indicator === "admin" ? "100" : "150"}
            />
            <ColumnDirective field="title" headerText="Title" width="150" />
            <ColumnDirective
              field="startDate"
              headerText="Start Date"
              width="180"
            />
            <ColumnDirective
              field="closeDate"
              headerText="Close Date"
              width="180"
            />
            <ColumnDirective
              headerText="Actions"
              template={getActionItemsTemplate}
              allowSearching={false}
              allowFiltering={false}
              allowSorting={false}
              width={indicator === "admin" ? "500" : "250"}
            />
          </ColumnsDirective>
          <Inject services={[Resize, Sort, Page]} />
        </GridComponent>
      </>
    );
  };

  const getLargeGrid = () => {
    return (
      <>
        <GridComponent
          dataSource={mappedAuctions}
          allowSorting={true}
          allowTextWrap={true}
          allowResizing={true}
          allowPaging={true}
          pageSettings={{ ...gridPagerSettings() }}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="location"
              headerText="Location"
              width="75"
            />
            <ColumnDirective field="title" headerText="Title" width="75" />
            <ColumnDirective
              field="description"
              headerText="Description"
              width="100"
            />
            <ColumnDirective
              field="startDate"
              headerText="Start Date"
              width="50"
            />
            <ColumnDirective
              field="closeDate"
              headerText="Close Date"
              width="50"
            />
            <ColumnDirective
              headerText="Actions"
              template={getActionItemsTemplate}
              allowSearching={false}
              allowFiltering={false}
              allowSorting={false}
              width="100"
            />
          </ColumnsDirective>
          <Inject services={[Resize, Sort, Page]} />
        </GridComponent>
      </>
    );
  };

  return (
    <>
      <div>
        <CustomModal
          modalTitle={modalTitle}
          modalBody={modalBody}
          isOpen={isModalOpen}
          id={auctionId}
          isOpenChanged={(isOpen: boolean) => setIsModalOpen(isOpen)}
          isSuccess={isUpdated}
          isConfirmSuccess={(isSuccess: boolean) => {
            if (isUpdateChanged) {
              isUpdateChanged(isSuccess);
            }
          }}
        />
      </div>
      <div className="container-fluid">
        <br />
        <Card>
          {indicator && indicator === "admin" ? (
            <CardHeader
              tag="a"
              title="Upcoming Auctions"
              onClick={upcomingAuctionToggle}
            >
              {isUpcomingAuctionOpen ? (
                <FontAwesomeIcon icon="minus-circle" />
              ) : (
                <FontAwesomeIcon icon="plus-circle" />
              )}{" "}
              Upcoming Auctions
            </CardHeader>
          ) : (
            <CardHeader tag="h5" title="Upcoming Auctions">
              Upcoming Auctions
            </CardHeader>
          )}
          <Collapse isOpen={isUpcomingAuctionOpen}>
            <CardBody>
              {items.length === 0 ? (
                <h5 className="text-center">No upcoming auctions found!</h5>
              ) : (
                <>
                  <div className="d-none d-xl-block">{getLargeGrid()}</div>
                  <div className="d-none d-sm-block d-xl-none">
                    {getMidSizeGrid()}
                  </div>
                  <div className="d-block d-sm-none">{getSmallGrid()}</div>
                </>
              )}
            </CardBody>
          </Collapse>
        </Card>
      </div>
    </>
  );
};

export default UpcomingAuctionsList;
