import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NotAuthorized = () => {
  return (
    <>
      <div className="row">
        <div className="col text-center my-auto">
          <FontAwesomeIcon icon="exclamation-triangle" size="6x" color="red" />
        </div>
        <div className="col display-3 p-3 text-center my-auto">
          You do not have access to perform this action. Please Login if you
          have not already!
        </div>
        <div className="col text-center my-auto">
          <FontAwesomeIcon
            icon="exclamation-triangle"
            size="6x"
            color="red"
          ></FontAwesomeIcon>
        </div>
      </div>
    </>
  );
};

export default NotAuthorized;