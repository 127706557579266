import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import CreateAuction from "./CreateAuction";
import AuctionsList from "./AuctionsList";
import ClosedAuctionsList from "./ClosedAuctionsList";
import { toast } from "react-toastify";
import Loading from "./Loading";
import { IAuction } from "../models/IAuction";
import * as userApi from "../Apis/user-api";
import { appInsights } from "../helpers/AppInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import ErrorPage from "../common/Error";
import UpcomingAuctionsList from "./UpcomingAuctionsList";

const Admin = () => {
  const [openAuctions, setOpenAuctions] = useState<Array<IAuction>>([]);
  const [closedAuctions, setClosedAuctions] = useState<Array<IAuction>>([]);
  const [upcomingAuctions, setUpcomingAuctions] = useState<Array<IAuction>>([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isClosedUpdated, setIsClosedUpdated] = useState(false);
  const [isUpcomingUpdated, setIsUpcomingUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasErrored, setHasErrored] = useState(false);
  const [key, setKey] = useState("createAuction");

  useEffect(() => {
    const getOpenAuctions = async () => {
      setIsUpdated(false);
      try {
        const responseData: Array<IAuction> = await userApi.getOpenAuctions();
        setOpenAuctions(responseData);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setHasErrored(true);
        error.response.status === 500
          ? toast.error(`ERROR: ${error.response.data.message}`)
          : toast.error(`ERROR: ${error.response.statusText}`);
        appInsights.trackException({
          error,
          id: "Fetching open auctions",
          severityLevel: SeverityLevel.Error
        });
      }
    };

    getOpenAuctions();
  }, [isUpdated]);

  useEffect(() => {
    const getClosedAuctions = async () => {
      try {
        setIsClosedUpdated(false);
        const responseData: Array<IAuction> = await userApi.getClosedAuctions();
        setClosedAuctions(responseData);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setHasErrored(true);
        error.response.status === 500
          ? toast.error(`ERROR: ${error.response.data.message}`)
          : toast.error(`ERROR: ${error.response.statusText}`);
        appInsights.trackException({
          error,
          id: "Fetching closed auctions",
          severityLevel: SeverityLevel.Error
        });
      }
    };

    getClosedAuctions();
  }, [isClosedUpdated, isUpdated, isUpcomingUpdated]);

  useEffect(()=> {
    const getUpcomingAuctions = async () => {
      try {
        setIsUpcomingUpdated(false);
        setIsClosedUpdated(false);
        const responseData: Array<IAuction> = await userApi.getUpcomingAuctions();
        setUpcomingAuctions(responseData);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setHasErrored(true);
        error.response.status = 500 ?
        toast.error(`ERROR: ${error.response.data.message}`) 
        : toast.error(`ERROR: ${error.response.statusText}`);
        appInsights.trackException({
          error,
          id: 'Fetching upcoming auctions',
          severityLevel: SeverityLevel.Error
        });
      }
    };

    getUpcomingAuctions();
  }, [isClosedUpdated, isUpcomingUpdated]);

  useEffect(() => {
    const previousTab = sessionStorage.getItem("adminTabs");
    if (previousTab !== null) {
      setKey(previousTab);
    }
  }, [key]);

  const onTabClick = (k: string) => {
    setKey(k);
    sessionStorage.setItem("adminTabs", k);
  };

  if (isLoading) {
    return <Loading loading={isLoading} />;
  }

  if (hasErrored) {
    return <ErrorPage model={"auctions"} />;
  }

  return (
    <>
      <div className="container-fluid">
        <Tabs activeKey={key} id="adminTabs" onSelect={onTabClick}>
          <Tab eventKey="createAuction" title="Create Auction">
            <CreateAuction></CreateAuction>
          </Tab>
          <Tab eventKey="deleteAuctionOrBid" title="Delete Auction/Bid">
            <div>
              <AuctionsList
                items={openAuctions}
                indicator={"admin"}
                isUpdated={isUpdated}
                isUpdateChanged={(isUpdated: boolean) => {
                  setIsUpdated(isUpdated);
                }}
              />
            </div>
            <div>
              <ClosedAuctionsList
                items={closedAuctions}
                indicator={"admin"}
                isUpdated={isClosedUpdated}
                isUpdateChanged={(isUpdated: boolean) => {
                  setIsClosedUpdated(isUpdated);
                }}
              />
              <div>
                <UpcomingAuctionsList 
                items={upcomingAuctions} 
                indicator={"admin"}
                isUpdated={isUpcomingUpdated}
                isUpdateChanged={(isUpdated: boolean) => {
                  setIsUpcomingUpdated(isUpdated);
                }}
                />
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default Admin;
