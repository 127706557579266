import { IAuction, IAuctionGridComponent } from "../models/IAuction";
import { IBid, IBidGridComponent } from "../models/IBid";
import { convertToCurrency } from "../helpers/helper";
import moment from "moment";

export const auctionsDataHelper = (
  item: Array<IAuction>
): Array<IAuctionGridComponent> => {
  return item.map(x => ({
    ...x,
    highestBid:
      x.bids.length > 0
        ? convertToCurrency.format(Number(x.bids[0].amount))
        : convertToCurrency.format(Number(x.initialBidAmount)),
    closeDate: `${x.closeDate} 05:00 PM`,
    startDate: `${x.startDate} 07:00 AM`,
    createdBy: x.firstName === 'System' ? 'System' : x.firstName + " " + x.lastName
  }));
};

export const bidsDataHelper = (item: Array<IBid>) : Array<IBidGridComponent> => {
    return item.map(x=> ({
        ...x,
        fullName: x.firstName + " " + x.lastName,
        date: moment(x.submissionDate).format("MM/DD/YYYY hh:mm:ss A"),
        bidAmount: convertToCurrency.format(Number(x.amount))
    }))
}