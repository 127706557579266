import moment from "moment";

export const convertCurrencyToNumber = (val: string) => {
  return Number(val.toString().replace("$", "").replace(/,/g, ""));
};

export const convertToCurrency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
});

//iso format is yyyy-mm-dd
export const getTodaysIsoDate = (): string => {
  const today = new Date();
  const month = `0${today.getMonth() + 1}`.slice(-2);
  const date = `0${today.getDate()}`.slice(-2);
  return `${today.getFullYear()}-${month}-${date}`;
};

export const localDate = (date: Date | string): string => {
  return moment(date).tz("America/New_York").format("L");
};

export const gridPagerSettings = () => {
  return {
    pageSize: 10,
    pageSizes: true,
  };
};
