import React, {useContext, useEffect} from 'react';
import localForage from 'localforage';
import {userContext} from '../App';

const NoMatch = () => {

    const consumerContext = useContext(userContext);

    useEffect(()=> {
        if(consumerContext===undefined){
            deepLinkUser();
        }
    });

    const deepLinkUser = () => {
        const url = window.location.href;
        localForage.setItem('deep-link-redirect-url', url)
        .then(()=> {
            window.location.href= `${process.env.REACT_APP_SERVER_BASE_URL}/login`;
        });
    }

    return(<h1>
        Could not find page associated with URL!!
    </h1>);
}

export default NoMatch;