import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Auctions from "./Auctions";
import Admin from "./Admin";
import SubmitBid from "./SubmitBid";
import ViewBid from "./ViewBid";
import NotAuthorized from "../common/NotAuthorized";
import { userContext } from "../App";
import { Home } from "./Home";
import NoMatch from "./NoMatch";
import Dashboard from './Dashboard';

const Routes = () => {
  const contextConsumer = useContext(userContext);

  const getAuthenticatedRoutes = (): JSX.Element[] => {
    if(contextConsumer && contextConsumer.isAuthenticated){
      const routes: Array<JSX.Element> =[];
      routes.push(<Route exact path="/Auction/View/:auctionId" key='ViewAuction' component={ViewBid}></Route>);
      // routes.push(<Route exact path="/Auction/:auctionId" key='Bid' component={SubmitBid}></Route>);
      routes.push(<Route exact path="/Dashboard" key='dashboard' component={Dashboard}></Route>);
      return routes;
    } else {
      return [];
    }
  }

  const getAdminRoutes = (): JSX.Element[] => {
    if(contextConsumer && contextConsumer.isAuthenticated && contextConsumer.isAdmin){
      const routes: Array<JSX.Element> =[];
      routes.push(<Route exact path="/Admin" key='Admin' component={Admin}></Route>);

      return routes;
    } else {
      return [];
    }
  }

  const getMainLandingPage = (): JSX.Element => {
    if (contextConsumer && contextConsumer.isAuthenticated) {
      return <Route exact path="/" component={Auctions}></Route>;
    } else {
      return <Route exact path="/" component={Home}></Route>;
    }
  };

  return (
    <>
      <Header></Header>
      <div id="maincontent" style={{ paddingBottom: "100px" }}>
        <Switch>
          {getMainLandingPage()}
          <Route exact path="/NotAuthorized" component={NotAuthorized}></Route>
          {getAuthenticatedRoutes()}
          {getAdminRoutes()}
          <Route component={NoMatch}></Route>
        </Switch>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Routes;
