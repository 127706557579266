import axios from "axios";
import { IAuction } from "../models/IAuction";
import {contentHeaders, imageContentHeader} from '../helpers/contentHeaders';
import { appInsights } from "../helpers/AppInsights";

const baseUrl = `${process.env.REACT_APP_SERVER_BASE_URL}/api`;

export const postImage = async (formData: FormData) => {

  appInsights.trackEvent({
    name: 'Saving Auction Image Event',
    properties: {
      formData
    }
  });

  const response = await axios.post(`${baseUrl}/Admin/uploadImage`, formData, {headers: imageContentHeader});
  return response;
}

//bid is stringified IAuction json
export const postAuction = async (values: string) : Promise<IAuction> => {
  appInsights.trackEvent({
    name: 'Saving Auction Event',
    properties: {
      values
    }
  });

  const response = await axios.post(`${baseUrl}/admin`, values, {headers: contentHeaders});
  return response.data;
}

export const deleteAuction = async (auctionId: string) => {
  appInsights.trackEvent({
    name: 'Delete Auction Event',
    properties: {
      auctionId
    }
  });

  const response = await axios.delete(`${baseUrl}/Admin/DeleteAuction/${auctionId}`);
  return response.data;
}

export const deleteBid = async (bidId: string) => {
  appInsights.trackEvent({
    name: 'Delete Bid Event',
    properties: {
      bidId
    }
  });

  const response = await axios.delete(`${baseUrl}/Admin/DeleteBid/${bidId}`);
  return response.data;
}

export const closeAuction = async (auctionId: string) => {
  appInsights.trackEvent({
    name: 'Close Auction Event',
    properties: {
      auctionId
    }
  });
  
  const response = await axios.patch(`${baseUrl}/admin/CloseAuction/${auctionId}`);
  return response.data;
}

export const closeUpcomingAuction = async (auctionId: string) => {
  appInsights.trackEvent({
    name: 'Close Upcoming Auction Event',
    properties: {
      auctionId
    }
  });

  const response = await axios.patch(`${baseUrl}/admin/CloseUpcomingAuction/${auctionId}`);
  return response.data;
}