import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faMoneyBillWave,
  faTimesCircle,
  faEye,
  faCheckCircle,
  faPlusCircle,
  faMinusCircle,
  faExclamationTriangle,
  faTrashAlt,
  faArrowCircleLeft
} from "@fortawesome/free-solid-svg-icons";

export function loadIcons(){
    library.add(faMoneyBillWave, faTimesCircle, faEye, faCheckCircle, faPlusCircle, faMinusCircle, faExclamationTriangle, faTrashAlt, faArrowCircleLeft);
}