import React, {useContext} from 'react';
import Auctions from './Auctions';
import {userContext} from '../App';
import localForage from 'localforage';
const Dashboard = () => {
    const consumerContext = useContext(userContext);
    const redirectDeepLink = async () => {
        if (consumerContext && consumerContext.isAuthenticated) {
          localForage.getItem("deep-link-redirect-url").then((redirectUrl: any) => {
            if (redirectUrl!=='' && redirectUrl !== undefined && redirectUrl !==null) {
              localForage.removeItem("deep-link-redirect-url").then(() => {
                window.location.href = redirectUrl;
              });
            }
          }).catch(err => alert('catch error: '+ err));
        }
      };
      redirectDeepLink();
    return <Auctions />
}
export default Dashboard;