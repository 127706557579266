import axios from "axios";
import { IUser } from "../models/IUser";
import { IAuction } from "../models/IAuction";
import { contentHeaders } from "../helpers/contentHeaders";
import { IBid } from "../models/IBid";
import { appInsights } from "../helpers/AppInsights";

const baseUrl = `${process.env.REACT_APP_SERVER_BASE_URL}/api`;

export const getUser = async (): Promise<IUser> => {
  const response = await axios.get(`${baseUrl}/user`);
  return response.data;
};

export const getOpenAuctions = async (): Promise<IAuction[]> => {
  const response = await axios.get(`${baseUrl}/getOpenAuctions`);
  return response.data.openAuctions;
};

export const getClosedAuctions = async (): Promise<IAuction[]> => {
  const response = await axios.get(`${baseUrl}/getClosedAuctions`);
  return response.data.closedAuctions;
};

export const getUpcomingAuctions = async (): Promise<IAuction[]> => {
  const response = await axios.get(`${baseUrl}/getUpcomingAuctions`);
  return response.data.upcomingAuctions;
}

//bid is stringified IBid json
export const postBid = async (bid: string): Promise<IBid>  => {
  appInsights.trackEvent({
    name: 'Saving Bid Event',
    properties: {
      bid
    }
  });

  const response = await axios.post(`${baseUrl}/postBid`, bid, {headers: contentHeaders});
  return response.data;
}

const getAuctionById = (auctionId: string | undefined) => {
  return axios.get(`${baseUrl}/getAuctionById/${auctionId}`);
}

const getBidHistoryByAuctionId = (auctionId: string | undefined) => {
  return axios.get(`${baseUrl}/getBidHistoryByAuctionId/${auctionId}`);
}

export const getAuctionAndBids = async (auctionId: string | undefined) => {
  return await axios.all([getAuctionById(auctionId), getBidHistoryByAuctionId(auctionId)]);
}