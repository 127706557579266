import React, { CSSProperties } from 'react';
import { IAuction } from '../models/IAuction';
import history from "../helpers/History";

export const titleTemplate = (item: IAuction) => {
    return (
      <a
        href={`/Auction/View/${item._id}`}
        title={item.title}
        onClick={(event) => {
          history.push(`/Auction/View/${item._id}`);
          event.preventDefault();
        }}
      >
        {item.title}
      </a>
    );
  };

 export const modalValues = {
  modalCloseBody: "Are you sure you want to close this auction?",
  modalDeleteBody: "Are you sure you want to delete this auction?",
  modalCloseTitle: "Close Auction",
  modalDeleteTitle: "Delete Auction",
  modalUpcomingCloseBody: "Are you sure you want to close this upcoming auction?",
  modalUpcomingDeleteBody: "Are you sure you want to delete this upcoming auction?",
  modalUpcomingCloseTitle: "Close Upcoming Auction",
  modalUpcomingDeleteTitle: "Delete Upcoming Auction",
  modalDeleteBidTitle: 'Delete Bid',
  modalDeleteBidBody: 'Are you sure you want to delete this bid?'
};

export const inputWidth: CSSProperties = {
  width: "75%"
};